import { Component } from '@angular/core';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { BrandingService } from '../../../pages/branding/branding.service';
import { Branding } from '../../../pages/branding/branding';
import { User } from '../../../pages/user/user';
import { AUserService } from '../../../pages/user/user.service';

declare var window: any;

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <span class="created-by">{{domain_footer}}</span>
  <!--
  <div class="socials">
    <a href="https://github.com/ictinnovations/ictfax" target="_blank" class="ion ion-social-github"></a>
    <a href="https://www.facebook.com/ICTInnovations/" target="_blank" class="ion ion-social-facebook"></a>
    <a href="https://twitter.com/tahiralmas" target="_blank" class="ion ion-social-twitter"></a>
    <a href="https://pk.linkedin.com/company/ict-innovations" target="_blank" class="ion ion-social-linkedin"></a>
  </div>
  !-->
  `,
})
export class FooterComponent {

  user_id = 1;
  auser: any;
  branding: Branding = new Branding;
  user: User = new User;  
  domain_footer: string = 'Copyrights ICT Innovations 2024';

  constructor(private authService: NbAuthService, private branding_service: BrandingService, private user_service: AUserService) {

    this.authService.onTokenChange()
    .subscribe((token: NbAuthJWTToken) => {
      this.auser = token.getPayload();
      this.get_domain_footer(this.auser.tenant_id);
      // const brand:any = this.get_domain_footer(this.auser.tenant_id);
      // if (!brand) this.get_domain_footer(0);
    });
  }
  get_domain_footer(tenant_id) { 
    this.branding_service.get_Branding(tenant_id).then(response => {
      this.domain_footer = response.footer;
    })
    .catch(err => {
      console.log(err);
      this.search_branding();
    })
  }
  
  search_branding() {
    this.user_service.get_UserData(this.user_id).then(response => {
      this.user.tenant_id = response['tenant_id'];
      this.get_branding();
    });
  }


  get_branding() {
      this.branding_service.get_Branding(this.user.tenant_id).then(response => {
        this.branding.defaultvalue = response.defaultvalue;
        if (this.branding.defaultvalue < 1) {
        console.log(this.branding.defaultvalue);
      } else {
        this.get_domain_footer(this.user.tenant_id);
      }
    });
  }

}


