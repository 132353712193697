export class Rate {
  rate_id: number;
  name: string;
  unit_billing_block: number;
  unit_first_block: number;
  unit_increment: number
  unit_free: number;
  unit_block_rate: number;
  cost_connection: number;
  cost_minimum: number;
  weight: number;
  active: number
  plan_id: number
  service_flag: number;
  destination_id: any;
}
