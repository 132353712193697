export class Tenant {
  tenant_id: number;
  domain: string;
  title: string;
  logo_name: string;
  footer: string;
  first_name: string;
  last_name: string;
  company: string;
  phone: number;
  email: string;
  address: string;
  country_id: number;
  timezone_id: number;
  active: number;
  daily_limit: number = 0;
  monthly_limit: number = 0;
}